var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Information")]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Page Title")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"assessmentTitle","name":"Assessment Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Page Description")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"assessmentDescription","name":"Assessment Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Status")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-2"},[_c('ValidationProvider',{attrs:{"vid":"assessmentStatus","name":"Assessment Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-select',{attrs:{"placeholder":"Select a type","expanded":""},model:{value:(_vm.data.status),callback:function ($$v) {_vm.$set(_vm.data, "status", $$v)},expression:"data.status"}},[_c('option',{attrs:{"value":"Active"}},[_vm._v("Active")]),_c('option',{attrs:{"value":"Inactive"}},[_vm._v("Inactive")])])],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-2"},[_c('b',[_vm._v("Category")]),_c('b-tooltip',{attrs:{"type":"is-danger","label":"This field is required"}},[_c('span',{staticClass:"required"},[_vm._v("*")])])],1),_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"vid":"assessmentStatus","name":"Assessment Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-taginput',{attrs:{"data":_vm.categoryTags,"open-on-focus":false,"loading":_vm.isFetching,"autocomplete":"","maxtags":"1","placeholder":"Type to search"},on:{"typing":_vm.getCategory},model:{value:(_vm.data.category),callback:function ($$v) {_vm.$set(_vm.data, "category", $$v)},expression:"data.category"}},[_c('template',{slot:"empty"},[_vm._v("There are no items")])],2)],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }