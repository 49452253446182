<template>
  <div>
    <h1 class="title has-text-centered">Thank You Page</h1>
    <!-- For Title Field -->
    <div class="columns is-12">
      <div class="column is-2">
        <b>Title</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-10">
        <ValidationProvider
          :vid="`thankYouPageTitle`"
          name="Thank You Page Title"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input v-model="data.title"></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- For Rules Description -->
    <div class="columns is-12">
      <div class="column is-2">
        <b>Description</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-10">
        <ValidationProvider
          :vid="`thankYouPageDescription`"
          name="Thank You Page Description"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <ckeditor v-model="data.description" :config="editorConfig" />
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: "div[class]",
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: "justify,font"
      }
    };
  }
};
</script>
