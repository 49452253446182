<template>
  <section class="main" v-if="!isLoading" ref="element">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline" v-if="!isLoading">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Case Study Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Case Study</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `caseStudy` }">
                      <h3 class="is-size-6">Case Study Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3
                      class="is-size-6 breadcrumb-item"
                      v-if="$route.name == 'createCaseStudy'"
                    >
                      Create Case Study
                    </h3>
                    <h3 class="is-size-6 breadcrumb-item" v-else>
                      Edit Case Study
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column bar">
              <b-field grouped position="is-right">
                <b-button class="is-hcc" native-type="submit">Save</b-button>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <div class="box">
                <AssessmentInformation :data="information" />
              </div>

              <div class="box">
                <AssessmentThankYouPage :data="thankYouPage" />
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import AssessmentInformation from "@/components/AssessmentForm/AssessmentInformation";
import AssessmentThankYouPage from "@/components/AssessmentForm/AssessmentThankYouPage";
export default {
  components: {
    AssessmentInformation,
    AssessmentThankYouPage
  },
  name: "create-case-study",
  data() {
    return {
      // For Loading Page
      isLoading: false,

      // For Information Data
      information: {
        title: "",
        description: "",
        status: "Active",
        category: []
      },

      // For Thank You Page Data
      thankYouPage: {
        title: "",
        description: ""
      },

      isUseRules: true,

      isEditing: false
    };
  },
  watch: {},
  created() {
    if (this.$route.name == "editCaseStudy") {
      this.fetchData();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getCaseStudy",
        this.$route.params.caseStudyId
      );
      this.thankYouPage = response.data.thankYouPage;
      this.information = response.data.information;
      this.isLoading = false;
    },
    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: "Save Case Study",
        message: `Are you sure want to save this case study?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, save it!",
        type: "is-hcc",
        onConfirm: () => this.createCaseStudy()
      });
    },

    // For store case study
    async createCaseStudy() {
      if (this.$route.name != "editCaseStudy") {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el
        });
        await this.$store
          .dispatch("createCaseStudy", {
            // For send data of information
            pageTitle: this.information.title,
            pageDescription: this.information.description,
            category: this.information.category[0],
            status: this.information.status,
            thankYouPageTitle: this.thankYouPage.title,
            thankYouPageDescription: this.thankYouPage.description
          })
          .then(response => {
            if (response.data.status == 200) {
              this.isEditing = true;
              this.success("Successfully create case study");
              loadingComponent.close();
              this.$router.push({ name: "caseStudy" });
            } else {
              this.danger("failed to create a new case study");
            }
          })
          .catch(() => {
            this.danger("Failed to create a new case study");
            loadingComponent.close();
          });
      } else {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el
        });
        await this.$store
          .dispatch("editCaseStudy", {
            // For send data of information
            pageTitle: this.information.title,
            pageDescription: this.information.description,
            category: this.information.category[0],
            status: this.information.status,

            thankYouPageTitle: this.thankYouPage.title,
            thankYouPageDescription: this.thankYouPage.description,
            id: this.$route.params.caseStudyId
          })
          .then(() => {
            this.isEditing = true;
            this.success("Successfully create case study");
            loadingComponent.close();
            this.$router.push({ name: "caseStudy" });
          })
          .catch(() => {
            this.danger("Failed to create a new case study");
            loadingComponent.close();
          });
      }
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing == false) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
<style>
.prev {
  margin-left: 1.1% !important;
}
.button-next {
  margin-right: 1% !important;
}
</style>
