<template>
  <div>
    <h1 class="title has-text-centered">Information</h1>
    <div class="columns">
      <div class="column is-2">
        <b>Page Title</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-10">
        <ValidationProvider
          :vid="`assessmentTitle`"
          name="Assessment Title"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input v-model="data.title"></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b>Page Description</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-10">
        <ValidationProvider
          :vid="`assessmentDescription`"
          name="Assessment Description"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input type="textarea" v-model="data.description"></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b>Status</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-2">
        <ValidationProvider
          :vid="`assessmentStatus`"
          name="Assessment Status"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-select
              placeholder="Select a type"
              v-model="data.status"
              expanded
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </b-select>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b>Category</b>
        <b-tooltip type="is-danger" label="This field is required">
          <span class="required">*</span>
        </b-tooltip>
      </div>
      <div class="column is-10">
        <ValidationProvider
          :vid="`assessmentStatus`"
          name="Assessment Status"
          rules="required"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-taginput
              v-model="data.category"
              :data="categoryTags"
              :open-on-focus="false"
              :loading="isFetching"
              autocomplete
              maxtags="1"
              placeholder="Type to search"
              @typing="getCategory"
            >
              <template slot="empty">There are no items</template>
            </b-taginput>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      categoryTags: [],
      isFetching: true
    };
  },
  methods: {
    getCategory: debounce(function(search) {
      this.isFetching = true;
      this.$store.dispatch("searchCategory", search).then(data => {
        if (!data) {
          this.categoryTags = [];
        } else {
          this.categoryTags = data.category;
        }
      });
      this.isFetching = false;
    }, 500)
  }
};
</script>
